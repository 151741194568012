import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TutorialGuard } from './guards/tutorial.guard';

const routes: Routes = [
  { path: '', 
    redirectTo: 'stop', pathMatch: 'full' 
  },
  // { path: 'home', 
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  //   // REMOVE Tutorial Guard replace by Home ionViewWillEnter shouldDisplayDisclaimer,
  //   // canActivate: [TutorialGuard]
  // },
  // {
  //   path: 'calc',
  //   loadChildren: () => import('./calc/calc.module').then( m => m.CalcPageModule)
  // },
  // {
  //   path: 'calc-scanner',
  //   loadChildren: () => import('./calc-scanner/calc-scanner.module').then( m => m.CalcScannerPageModule)
  // },
  // {
  //   path: 'tutorial',
  //   loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialPageModule)
  // },
  {
    path: 'stop',
    loadChildren: () => import('./stop/stop.module').then( m => m.StopPageModule)
  },
  {path: '**', redirectTo: '/stop'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
